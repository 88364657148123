import React from 'react'

//const WebApiDomain = 'https://localhost:44348';
const WebApiDomain = 'https://dev01.neuronica.it/AssindatColf';
//const WebApiDomain = 'https://prd01.neuronica.it/AssindatColf';

const WebApiRootUrlPart1 = 'api';
const WebApiRootUrlPart2 = 'xB52hZf8wj';
const WebApiRootUrlVersion = 'v1';

export const WebApiRootUrl = WebApiDomain + '/' + WebApiRootUrlPart1 + '/' + WebApiRootUrlPart2 + '/' + WebApiRootUrlVersion;
export const WebApiLoginUrl = WebApiDomain + '/' + 'edb1c77e-9cfb-4315-9468-4977ae16a787';

export const LocalStorageKeyFilterTransformations = "ac-transformations-filter";

export const LocalStorageKey = {
    FilterTransformations: 'ac-transformations-filter',
    ToastMessage: 'ac-toast-message',
    Year: 'ac-year',
};

export function IsLoggedUserAdmin() {
    //var user = JSON.parse(localStorage.getItem('ac-user'));
    var user = GetLoggedUser();
    var isAdmin = false;
    if (user) {
        //console.log("gblIsLoggedUserAdmin:", user);
        if (user.roleName && user.roleName === "Amministratore") {
            isAdmin = true;
            //console.log("gblIsLoggedUserAdmin: user ADMIN");
        }
    }
    return isAdmin;
}

export function GetLoggedUser() {
    var user = JSON.parse(localStorage.getItem('ac-user'));
    if (user && user.token != "" & user.userId != "") {
        return user;
    } else {
        Logout();
    }
    return null;
}

export function CheckResponseAuth(xhr) {
    if(xhr && xhr.status && [401, 403].indexOf(xhr.status) !== -1) {
        Logout();
    }
}

export function Logout() {
    localStorage.removeItem('ac-user');
    localStorage.removeItem('ac-stakeholders-filter');
    localStorage.removeItem('ac-report-filter');
    localStorage.removeItem(LocalStorageKey.Year);
    localStorage.removeItem(LocalStorageKey.FilterTransformations);
    window.location.reload();
}